import React from "react"
import { graphql } from "gatsby"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../components/layout.component"

const Company = props => {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src={props.data.strapiCompany.cover.publicURL}
                alt={props.data.strapiCompany.cover.name}
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src={props.data.strapiCompany.logo.publicURL}
                      alt={props.data.strapiCompany.logo.name}
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                {props.data.strapiCompany.name}
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                {props.data.strapiCompany.excerpt}
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: {props.data.strapiCompany.hourly_rate}
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: {props.data.strapiCompany.employees}
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href={props.data.strapiCompany.website} target="_blank">
                  {props.data.strapiCompany.website}
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | {props.data.strapiCompany.address}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography
                variant="body1"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: props.data.strapiCompany.description,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
export default Company

export const companyQuery = graphql`
  query CompanyQuery($id: String!) {
    strapiCompany(id: { eq: $id }) {
      id
      name
      founded_in
      excerpt
      description
      address
      hourly_rate
      type
      employees
      website
      office_locations
      location
      slug
      logo {
        publicURL
        name
      }
      cover {
        publicURL
        name
      }
      tags {
        id
        name
        slug
      }
    }
  }
`
